var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "404" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "", row: "" } },
            [
              _c("div", { staticClass: "mr-3 hidden-sm-and-down" }, [
                _c("img", { attrs: { src: "/static/error/500.svg", alt: "" } }),
              ]),
              _c("div", { staticClass: "text-md-center" }, [
                _c("h1", [_vm._v("500")]),
                _c("h2", { staticClass: "my-3 headline " }, [
                  _vm._v("Sorry, the server is down."),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.goHome },
                      },
                      [_vm._v("Go Home")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }