var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "flashable", style: _vm.style }, [
    _vm._v(" " + _vm._s(_vm.finalValue)),
    _vm.suffix ? _c("small", [_vm._v(" " + _vm._s(_vm.suffix))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }