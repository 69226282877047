// @ts-ignore

const config = {
  defaults: {
    theme: "light",
  },
  api: {
    url: process.env.API_URL,
  },
  front: {
    url: process.env.FRONT_OFFICE_URL,
  },
  editorToolbar: [
    [{ header: [false, 3, 4, 5, 6] }],
    ["bold"],
    /*
        [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
        ],

        ["blockquote"],*/
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "super" }],
    ["clean"],
  ],
};

export default Object.assign({}, config);
