var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "grid-view" },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "6" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.title))]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-end", attrs: { md: "6" } },
                [_vm._t("actions")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }