

























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import Debug from "@/components/Debug.vue";
@Component({
  components: { Debug },
})
export default class GridView extends Vue {
  @Prop() endpoint!: string;
  @Prop() model!: string;
  @Prop() headers!: DataTableHeader[];
  @Prop() data!: any[];

  loading = false;
  flashId = null as number | null;
  options = {} as DataOptions;
  models = [];
  httpHeaders = {} as Record<string, number>;
  filters = {} as Record<string, string>;
  itemsSelected = []
  filterDebounce!: NodeJS.Timeout;

  get tableHeaders() {
    return this.headers.map(record => {
      if (record.value === "_actions" && this.model != "company") {
        return {
          ...record,
          sortable: false,
        };
      }

      return record;
    });
  }

  updateSelection() {
    // console.log(this.itemsSelected)
    this.$emit('input', this.itemsSelected); // Emit updated selection
    console.log("model", this.models)
    this.$emit('selection-change', {
      selectedItems: this.itemsSelected,
      allItems: this.models // Emit all data
    }); // Custom event for selection changes
  }
  @Watch("options", { deep: true })
  optionsChanged() {
    this.getModels();
  }
  @Watch('endpoint')
  onEndpointChanged() {
    this.getModels();
  }
  @Watch("filters", { deep: true })
  filtersChanged() {
    clearTimeout(this.filterDebounce);
    this.filterDebounce = setTimeout(() => this.getModels(), 300);
  }

  getUrl(): string {
    const {
      sortBy = [],
      sortDesc = [],
      page = 1,
      itemsPerPage = 25,
    } = this.options;

    let sortParam: string[] = [];
    sortBy.forEach((field, idx) => {
      sortParam.push(
        `${sortDesc[idx] ? "-" : ""}${this.prefixWithModel(field)}`
      );
    });
    let filterParam: string[] = [];
    let filterParamStatus: string[] = [];
    Object.keys(this.filters).forEach(key => {

      if (this.filters[key]) {
        if (key != "_actions") {
          filterParam.push(
            `filter[${this.prefixWithModel(key)}][like]=${this.filters[key]}`
          );
        } else {
          filterParamStatus.push(
            `filter[enabled][in][]=${this.filters[key]}`
          );
        }
        console.log("filterParamStatus", filterParamStatus)
      }
      // if(this.filters[key] == )
    });

    let url = `${this.endpoint}${this.endpoint.includes("?") ? "&" : "?"}`;
    let params = `page=${page}&per-page=${itemsPerPage}&${filterParamStatus}&sort=${sortParam.join(
      ","
    )}&${filterParam.join("&")}`;
    console.log("filters", filterParam)

    return url + params;

  }

  prefixWithModel(field: string) {
    return field.includes(".") ? field : `${this.model}.${field}`;
  }

  getModels(flashId: number | null = null) {
    this.flashId = flashId;
    this.loading = true;
    this.$api.get(this.getUrl()).then(res => {
      this.httpHeaders = {
        totalCount: parseInt(res.headers["x-pagination-total-count"]),
      };
      this.models = res.data;
      this.loading = false;
      this.$emit('data-loaded', this.models);
      if (flashId !== null) {
        setTimeout(() => (this.flashId = null), 1000);
      }
    });
  }

  rowClass(item: { id: number }) {
    if (item.id === this.flashId) {
      return "flash-row";
    }

    return undefined;
  }
}
