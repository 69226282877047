var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: "primary", timeout: -1, app: "" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function (ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "pink", text: "" },
                        on: { click: _vm.refreshApp },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Update ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.updateExists,
            callback: function ($$v) {
              _vm.updateExists = $$v
            },
            expression: "updateExists",
          },
        },
        [_vm._v(" An update is available ")]
      ),
      _c("transition", { attrs: { mode: "out-in" } }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }