var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.$store.state.snack.color },
      model: {
        value: _vm.$store.state.snack.show,
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.snack, "show", $$v)
        },
        expression: "$store.state.snack.show",
      },
    },
    [
      _vm.$store.state.snack.html
        ? _c("div", {
            domProps: { innerHTML: _vm._s(_vm.$store.state.snack.html) },
          })
        : _vm.$store.state.snack.text
        ? _c("div", {
            domProps: { textContent: _vm._s(_vm.$store.state.snack.text) },
          })
        : _vm._e(),
      _c(
        "v-btn",
        {
          attrs: { text: "" },
          on: {
            click: function ($event) {
              _vm.$store.state.snack.show = false
            },
          },
        },
        [_vm._v("Close")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }