var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.version !== _vm.updateKey
        ? _c(
            "v-btn",
            {
              attrs: { color: "warning", block: "" },
              on: {
                click: function ($event) {
                  _vm.updateKey = _vm.version
                },
              },
            },
            [_vm._v("Data has changed => Update Graphs")]
          )
        : _vm._e(),
      _vm._l(_vm.data.axes, function (axe) {
        return _c(
          "div",
          { key: axe.id, staticClass: "mb-4" },
          [
            _c("h2", { staticClass: "mt-0" }, [
              _vm._v(_vm._s(_vm.translateName(axe))),
            ]),
            _vm._l(axe.children, function (theme) {
              return _c(
                "div",
                { key: theme.id },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "d-inline",
                      attrs: { id: "title-theme-" + theme.id },
                    },
                    [_vm._v(_vm._s(_vm.translateName(theme)))]
                  ),
                  _c(
                    "v-row",
                    _vm._l(theme.children, function (instrument) {
                      return _c(
                        "v-col",
                        { key: instrument.id, attrs: { md: "6" } },
                        [
                          _c(
                            "v-lazy",
                            [
                              _c("instrument-card", {
                                key: instrument.id + "-" + _vm.updateKey,
                                attrs: {
                                  company: _vm.company,
                                  instrument: instrument,
                                  "xml-chart": _vm.data.instrumentGraph,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("h3", [_vm._v("Conclusion du thème")]),
                  _c(
                    "div",
                    _vm._l(_vm.cgs[theme.id], function (cg, cgIdx) {
                      return _c(
                        "div",
                        { key: cg.id, staticClass: "mb-5" },
                        [
                          _c(
                            "v-lazy",
                            { key: "laz" + cg.id, attrs: { height: "432px" } },
                            [
                              _c("ConclusionGraph", {
                                attrs: {
                                  "company-id": _vm.company.id,
                                  "graph-id": cg.id,
                                  graph: _vm.cgs[theme.id][cgIdx],
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-card", [
                            _c(
                              "div",
                              {
                                staticClass: "v-data-table v-data-table--dense",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "v-data-table__wrapper" },
                                  [
                                    _c("table", [
                                      _c("thead", [
                                        _c(
                                          "tr",
                                          [
                                            _c("th"),
                                            _vm._l(
                                              Object.keys(
                                                _vm.cgs[theme.id][cgIdx]
                                                  .conclusionGraphSeries[0]
                                                  .conclusionGraphData
                                              ),
                                              function (year) {
                                                return _c("th", { key: year }, [
                                                  _vm._v(
                                                    " " + _vm._s(year) + " "
                                                  ),
                                                ])
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.cgs[theme.id][cgIdx]
                                            .conclusionGraphSeries,
                                          function (serie) {
                                            return _c(
                                              "tr",
                                              {
                                                key: serie.id,
                                                class: serie.name.match(
                                                  /^Limite/
                                                )
                                                  ? "d-none"
                                                  : "",
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(serie.name)),
                                                ]),
                                                _vm._l(
                                                  Object.values(
                                                    serie.conclusionGraphData
                                                  ),
                                                  function (value) {
                                                    return _c(
                                                      "td",
                                                      { key: value.id },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.format(
                                                                value.value,
                                                                serie.val_format
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }