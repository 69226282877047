var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "widget-card" },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c("v-container", { staticClass: "pa-0" }, [
            _c(
              "div",
              {
                staticClass: "layout row ma-0",
                style: { backgroundColor: _vm.color },
              },
              [
                _c("div", { staticClass: "d-flex sm4 xs4 flex align-center" }, [
                  _c(
                    "div",
                    { staticClass: "layout column ma-0" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { opacity: "0.8" },
                          attrs: { size: "48px" },
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "layout column ma-0 justify-center" },
                  [
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(_vm.subTitle)),
                    ]),
                    _c("div", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(_vm.supTitle)),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }