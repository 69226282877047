var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "text-h5" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "v-card-text",
        _vm._l(_vm.list, function (l1, idx1) {
          var _obj
          return _c("div", { key: l1.code }, [
            _c(
              "label",
              {
                staticClass: "tr l1",
                class:
                  ((_obj = { odd: idx1 % 2, checked: l1.checked }),
                  (_obj[l1.type] = true),
                  _obj),
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: l1.checked,
                      expression: "l1.checked",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(l1.checked)
                      ? _vm._i(l1.checked, null) > -1
                      : l1.checked,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = l1.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(l1, "checked", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              l1,
                              "checked",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(l1, "checked", $$c)
                      }
                    },
                  },
                }),
                _c("div", { staticClass: "th code" }, [
                  _vm._v(_vm._s(l1.code)),
                ]),
                _c("div", { staticClass: "th name" }, [
                  _vm._v(_vm._s(_vm.translateName(l1))),
                ]),
              ]
            ),
            l1.checked
              ? _c(
                  "div",
                  _vm._l(l1.children, function (l2, idx2) {
                    var _obj
                    return _c("div", { key: l2.code }, [
                      _c(
                        "label",
                        {
                          staticClass: "tr l2",
                          class:
                            ((_obj = { odd: idx2 % 2, checked: l2.checked }),
                            (_obj[l2.type] = true),
                            _obj),
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: l2.checked,
                                expression: "l2.checked",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(l2.checked)
                                ? _vm._i(l2.checked, null) > -1
                                : l2.checked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = l2.checked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(l2, "checked", $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        l2,
                                        "checked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(l2, "checked", $$c)
                                }
                              },
                            },
                          }),
                          _c("div", { staticClass: "th code" }, [
                            _vm._v(_vm._s(l2.code)),
                          ]),
                          _c("div", { staticClass: "th name" }, [
                            _vm._v(_vm._s(_vm.translateName(l2)) + " "),
                          ]),
                        ]
                      ),
                      l2.checked
                        ? _c(
                            "div",
                            _vm._l(l2.children, function (l3, idx3) {
                              var _obj
                              return _c(
                                "label",
                                {
                                  key: l3.code,
                                  staticClass: "tr l3",
                                  class:
                                    ((_obj = {
                                      odd: idx3 % 2,
                                      checked: l3.checked,
                                    }),
                                    (_obj[l3.type] = true),
                                    _obj),
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: l3.checked,
                                        expression: "l3.checked",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(l3.checked)
                                        ? _vm._i(l3.checked, null) > -1
                                        : l3.checked,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = l3.checked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                l3,
                                                "checked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                l3,
                                                "checked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(l3, "checked", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "td code" }, [
                                    _vm._v(_vm._s(l3.code)),
                                  ]),
                                  _c("div", { staticClass: "td name" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.translateName(l3)) + " "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }