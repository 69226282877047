var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.i18n
    ? _c(
        "div",
        _vm._l(_vm.languages, function (l) {
          return _c(
            "field",
            _vm._b(
              {
                key: l,
                attrs: {
                  name: _vm.a,
                  label:
                    _vm.$t("models." + _vm.m + "." + _vm.a) + " (" + l + ")",
                  rules: _vm.rules[_vm.m][_vm.a],
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("input", _vm.v)
                  },
                },
                model: {
                  value: _vm.v[l],
                  callback: function ($$v) {
                    _vm.$set(_vm.v, l, $$v)
                  },
                  expression: "v[l]",
                },
              },
              "field",
              _vm.$attrs,
              false
            ),
            [
              _vm._t("default", function () {
                return [_vm._v("l")]
              }),
            ],
            2
          )
        }),
        1
      )
    : _c(
        "field",
        _vm._b(
          {
            attrs: {
              name: _vm.a,
              label: _vm.$t("models." + _vm.m + "." + _vm.a),
              rules: _vm.rules[_vm.m][_vm.a],
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", _vm.v)
              },
            },
            model: {
              value: _vm.v,
              callback: function ($$v) {
                _vm.v = $$v
              },
              expression: "v",
            },
          },
          "field",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }