var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    attrs: { dense: "", light: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-uppercase" }, [_vm._v("Année")]),
                _c("th", { staticClass: "text-uppercase text-center" }, [
                  _vm._v("Score global"),
                ]),
                _c("th", { staticClass: "text-uppercase text-center" }, [
                  _vm._v("Solidité financière"),
                ]),
                _c("th", { staticClass: "text-uppercase text-center" }, [
                  _vm._v("Performance financière"),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(Object.keys(_vm.scores).reverse(), function (year) {
                return _c("tr", { key: "year-" + year }, [
                  _c("th", [_vm._v(_vm._s(year))]),
                  _c("td", { class: _vm.getScoreClass("global", year) }, [
                    _vm._v(_vm._s(_vm.scores[year]["global"].toFixed(2))),
                  ]),
                  _c("td", { class: _vm.getScoreClass("A1", year) }, [
                    _vm._v(_vm._s(_vm.scores[year]["A1"].toFixed(2))),
                  ]),
                  _c("td", { class: _vm.getScoreClass("A2", year) }, [
                    _vm._v(_vm._s(_vm.scores[year]["A2"].toFixed(2))),
                  ]),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }