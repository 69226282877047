var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "editor",
      staticClass: "editor",
      attrs: { tabindex: "1" },
      on: {
        focus: _vm.onfocus,
        blur: _vm.onblur,
        mousedown: function ($event) {
          _vm.caret = _vm.code.length
        },
      },
    },
    [
      _vm._l(_vm.display, function (f, k) {
        return _c("span", {
          key: k,
          ref: "f" + k,
          refInFor: true,
          class: _vm.cls(f, k),
          style: _vm.style(f),
          attrs: { title: f.error },
          domProps: { innerHTML: _vm._s(_vm.html(f)) },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.codeMousedown($event, k)
            },
          },
        })
      }),
      _vm.showSelector
        ? _c("div", { staticClass: "selector" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectionFilter,
                  expression: "selectionFilter",
                },
              ],
              ref: "q",
              domProps: { value: _vm.selectionFilter },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    ) {
                      return null
                    }
                    return _vm.quitSelector.apply(null, arguments)
                  },
                  _vm.filterKeyHandler,
                ],
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.selectionFilter = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "selection-content" }, [
              _c(
                "table",
                { staticClass: "selection-list" },
                _vm._l(_vm.selection, function (v, idx) {
                  var _obj
                  return _c(
                    "tr",
                    {
                      key: idx,
                      ref: "item-" + idx,
                      refInFor: true,
                      staticClass: "selection-item",
                      class:
                        ((_obj = {}),
                        (_obj[v.type] = true),
                        (_obj.selected = _vm.selectionIndex === idx),
                        _obj),
                      attrs: { value: v.key },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "type",
                          on: {
                            click: function ($event) {
                              return _vm.insert({ v: v.key, type: "var" })
                            },
                          },
                        },
                        [_vm._v(_vm._s(v.code))]
                      ),
                      _c("td", { staticClass: "name" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insert({ v: v.key, type: "var" })
                              },
                            },
                          },
                          [_vm._v(_vm._s(v.name))]
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insert({
                                  v: v.key,
                                  type: "var",
                                  year: -1,
                                })
                              },
                            },
                          },
                          [_vm._v("[y-1]")]
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insert({
                                  v: v.key,
                                  type: "var",
                                  year: -2,
                                })
                              },
                            },
                          },
                          [_vm._v("[y-2]")]
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.insert({
                                  v: v.key,
                                  type: "var",
                                  year: -3,
                                })
                              },
                            },
                          },
                          [_vm._v("[y-3]")]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }