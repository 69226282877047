import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify";
import api from "@/services/api";
import i18n from '@/services/i18n';

import '@/services/validation';

// Plugins
import "./plugins/chartist";
import snack from "./plugins/snack";
import "./components";
import "./filters";
import cdnImage from "./mixins/cdn";

import "@/scss/nprogress.scss";
import "@/scss/app.scss";
import {registerPlugins} from "@/plugins";
// import * as Sentry from "@sentry/vue";


registerPlugins(Vue);

Vue.mixin(cdnImage);

Vue.prototype.$api = api;
Vue.prototype.$snack = snack;
Vue.config.productionTip = false;


// Sentry.init({
//   Vue,
//   environment:"development",
//   dsn: "https://24a99672f5ef099885a01433e0206868@o4507866198048768.ingest.de.sentry.io/4507866200080464",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^http:\/\/localhost:20081\/app/],
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
  
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount("#app");
