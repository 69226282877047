var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-view" },
    [
      _c(
        "v-data-table",
        _vm._b(
          {
            staticClass: "elevation-1",
            attrs: {
              "server-items-length": _vm.httpHeaders.totalCount,
              options: _vm.options,
              "multi-sort": "",
              dense: "",
              headers: _vm.tableHeaders,
              "footer-props": {
                "items-per-page-options": [25, 50, 100],
              },
              "mobile-breakpoint": 0,
              "item-class": _vm.rowClass,
              loading: _vm.loading,
              items: _vm.models,
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
              input: _vm.updateSelection,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (_, slot) {
                  return {
                    key: slot,
                    fn: function (scope) {
                      return [_vm._t(slot, null, null, scope)]
                    },
                  }
                }),
                {
                  key: "body.prepend",
                  fn: function (scope) {
                    return [
                      _c(
                        "tr",
                        _vm._l(scope.headers, function (col) {
                          return _c(
                            "td",
                            { key: col.value },
                            [
                              col.value !== "_actions" ||
                              _vm.model === "company"
                                ? _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      "single-line": "",
                                      outlined: "",
                                      "hide-details": "",
                                      placeholder: col.text
                                        ? "Filter by " + col.text
                                        : "Filter",
                                      disabled: col.filterable === false,
                                    },
                                    model: {
                                      value: _vm.filters[col.value],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, col.value, $$v)
                                      },
                                      expression: "filters[col.value]",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.itemsSelected,
              callback: function ($$v) {
                _vm.itemsSelected = $$v
              },
              expression: "itemsSelected",
            },
          },
          "v-data-table",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }