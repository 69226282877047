var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    [
      _c("v-list-item-avatar", [
        _c("div", { staticClass: "flag" }, [
          _vm._v(
            " " +
              _vm._s(_vm.flag(_vm.index.stockExchange.isin.substr(0, 2))) +
              " "
          ),
        ]),
      ]),
      _c(
        "v-list-item-content",
        {
          staticClass: "selected-item",
          on: {
            click: function ($event) {
              return _vm.viewIndex(_vm.index)
            },
          },
        },
        [
          _c("v-list-item-title", {
            domProps: { textContent: _vm._s(_vm.index.stockExchange.name) },
          }),
          _c("v-list-item-subtitle", {
            domProps: { textContent: _vm._s(_vm.index.stockExchange.isin) },
          }),
        ],
        1
      ),
      _c("v-list-item-action", [
        _vm._v(" " + _vm._s(_vm.index.stockExchange.last.now) + " "),
      ]),
      _c(
        "v-list-item-action",
        {
          staticClass: "selected-item",
          on: {
            click: function ($event) {
              return _vm.viewSetting(
                _vm.index,
                _vm.index.stockExchange.id,
                _vm.index.stockExchange.name
              )
            },
          },
        },
        [
          _c(
            "v-btn",
            { staticClass: "ma-2", attrs: { small: "" } },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("fa fa-cogs")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }