var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { overflow: "auto" } }, [
    _c("table", { ref: "table", staticClass: "table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th"),
            _vm._l(_vm.cols, function (col) {
              return _c("th", { key: "head-" + col.name }, [
                _vm._v(_vm._s(col.name)),
              ])
            }),
          ],
          2
        ),
      ]),
      _c(
        "tbody",
        _vm._l(_vm.items, function (item, idx) {
          return _c(
            "tr",
            { key: "row-" + idx },
            [
              _c(
                "td",
                [
                  _vm.isItemValid(item)
                    ? _c(
                        "v-icon",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.serverValidateItem(item)
                            },
                          },
                        },
                        [_vm._v("fa fa-check-circle")]
                      )
                    : _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("fa fa-times-circle"),
                      ]),
                ],
                1
              ),
              _vm._l(_vm.cols, function (col) {
                return _c(
                  "td",
                  {
                    key: "cell-" + col.name,
                    class: { err: !_vm.isColValid(item, col) },
                    attrs: { title: _vm.errorMessage(item, col) },
                  },
                  [
                    !col.if || item[col.if].value
                      ? [
                          col.choices
                            ? _c("v-select", {
                                attrs: {
                                  items: _vm.$store.getters.codes(col.choices),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateCol(item, col)
                                  },
                                },
                                model: {
                                  value: item[col.name].value,
                                  callback: function ($$v) {
                                    _vm.$set(item[col.name], "value", $$v)
                                  },
                                  expression: "item[col.name].value",
                                },
                              })
                            : col.bool
                            ? _c("v-checkbox", {
                                attrs: { "false-value": 0, "true-value": 1 },
                                on: {
                                  change: function ($event) {
                                    return _vm.validateItem(item)
                                  },
                                },
                                model: {
                                  value: item[col.name].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item[col.name],
                                      "value",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "item[col.name].value",
                                },
                              })
                            : _c("v-text-field", {
                                on: {
                                  input: function ($event) {
                                    return _vm.validateCol(item, col)
                                  },
                                },
                                model: {
                                  value: item[col.name].value,
                                  callback: function ($$v) {
                                    _vm.$set(item[col.name], "value", $$v)
                                  },
                                  expression: "item[col.name].value",
                                },
                              }),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
            ],
            2
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }