import { render, staticRenderFns } from "./InstrumentChart.vue?vue&type=template&id=45f98ded&"
import script from "./InstrumentChart.vue?vue&type=script&lang=ts&"
export * from "./InstrumentChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('45f98ded')) {
      api.createRecord('45f98ded', component.options)
    } else {
      api.reload('45f98ded', component.options)
    }
    module.hot.accept("./InstrumentChart.vue?vue&type=template&id=45f98ded&", function () {
      api.rerender('45f98ded', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/charts/InstrumentChart.vue"
export default component.exports