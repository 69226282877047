var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "widget-base",
    {
      staticClass: "mb-4",
      attrs: { title: "ChangeLog", icon: "fa-hourglass" },
    },
    [
      _c("ul", [
        _c("li", [_vm._v("7 Octobre 2021")]),
        _c("li", [_c("ul", [_c("li", [_vm._v("Livraison initiale")])])]),
        _c("li", [_vm._v("12 Novembre 2021")]),
        _c("li", [
          _c("ul", [
            _c("li", [_vm._v("Ajout générateur portefeuille client")]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }