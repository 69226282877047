var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", { attrs: { dense: "" } }, [
    !_vm.msi
      ? _c("thead", [
          _c(
            "tr",
            _vm._l(Object.keys(_vm.samples), function (time) {
              return _c(
                "th",
                {
                  key: "th-" + time,
                  staticStyle: { width: "20%", "text-align": "center" },
                },
                [_vm._v(" " + _vm._s(time) + " ")]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _c("tbody", [
      _c(
        "tr",
        _vm._l(Object.keys(_vm.samples), function (time) {
          return _c(
            "td",
            {
              key: "td-" + time,
              staticStyle: {
                width: "20%",
                "text-align": "center",
                padding: "0 8px",
              },
            },
            [
              _vm.samples[time] !== 0
                ? _c("flashable", {
                    attrs: {
                      value:
                        100 *
                        ((_vm.last - _vm.samples[time]) / _vm.samples[time]),
                      format: "decimal",
                      decimals: 2,
                      suffix: "%",
                    },
                  })
                : _c("span", [_vm._v("-")]),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }