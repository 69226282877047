var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.investment
    ? _c(
        "div",
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _vm.investment.id
                                ? _c("v-card-title", [
                                    _vm._v(
                                      "Editing " + _vm._s(_vm.investment.id)
                                    ),
                                  ])
                                : _c("v-card-title", [
                                    _vm._v("Creating new investment"),
                                  ]),
                              _c(
                                "v-card-text",
                                [
                                  _c("m-field", {
                                    attrs: {
                                      m: "investment",
                                      a: "investee_id",
                                      component: "v-select",
                                      items: _vm.companies,
                                      "item-value": "id",
                                      "item-text": "name",
                                    },
                                    model: {
                                      value: _vm.investment.investee_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.investment,
                                          "investee_id",
                                          $$v
                                        )
                                      },
                                      expression: "investment.investee_id",
                                    },
                                  }),
                                  _c("h2", [_vm._v("Rounds")]),
                                  _vm._l(
                                    _vm.investment.investmentRounds,
                                    function (ir, i) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          _c("b", [
                                            _vm._v("Round " + _vm._s(i + 1)),
                                          ]),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                "x-small": "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.investment.investmentRounds.splice(
                                                    i,
                                                    1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-small": "" } },
                                                [_vm._v("fa fa-trash")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { md: "3" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      name:
                                                        "investmentRounds[" +
                                                        i +
                                                        "].type",
                                                      component: "v-select",
                                                      items: [
                                                        "fund",
                                                        "direct",
                                                        "mezzanine",
                                                      ],
                                                      label: _vm.$t(
                                                        "models.investmentRound.type"
                                                      ),
                                                      type: "date",
                                                      rules:
                                                        _vm.rules
                                                          .investmentRound.type,
                                                    },
                                                    model: {
                                                      value: ir.type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          ir,
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "ir.type",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { md: "4" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      name:
                                                        "investmentRounds[" +
                                                        i +
                                                        "].fund_id",
                                                      component: "v-select",
                                                      items: _vm.funds,
                                                      label: _vm.$t(
                                                        "models.investmentRound.fund_id"
                                                      ),
                                                      "item-value": "id",
                                                      "item-text": "name",
                                                      rules:
                                                        _vm.rules
                                                          .investmentRound
                                                          .fund_id,
                                                    },
                                                    model: {
                                                      value: ir.fund_id,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          ir,
                                                          "fund_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "ir.fund_id",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { md: "2" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      name:
                                                        "investmentRounds[" +
                                                        i +
                                                        "].invested_at",
                                                      label: _vm.$t(
                                                        "models.investmentRound.invested_at"
                                                      ),
                                                      type: "number",
                                                      min: "1900",
                                                      rules:
                                                        _vm.rules
                                                          .investmentRound
                                                          .invested_at,
                                                    },
                                                    model: {
                                                      value: ir.invested_at,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          ir,
                                                          "invested_at",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ir.invested_at",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { md: "3" } },
                                                [
                                                  _c("field", {
                                                    attrs: {
                                                      name:
                                                        "investmentRounds[" +
                                                        i +
                                                        "].amount",
                                                      label: _vm.$t(
                                                        "models.investmentRound.amount"
                                                      ),
                                                      type: "number",
                                                      min: "0",
                                                      rules:
                                                        _vm.rules
                                                          .investmentRound
                                                          .amount,
                                                    },
                                                    model: {
                                                      value: ir.amount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          ir,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "ir.amount",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { "x-small": "" },
                                      on: { click: _vm.addRound },
                                    },
                                    [_vm._v("Add a round")]
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        text: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              998630248
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }