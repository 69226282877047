var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c(
        "v-alert",
        { attrs: { prominent: "", type: "error" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "grow",
                  staticStyle: { "white-space": "pre-wrap" },
                },
                [
                  _c("b", [_vm._v("ERROR #" + _vm._s(_vm.error.code))]),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.error.message) + " "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }