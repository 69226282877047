var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { class: "conformity-" + (_vm.conformity ? "yes" : "no") },
    [
      _c("v-card-title", [
        _c("h4", [_vm._v(_vm._s(_vm.translateName(_vm.instrument)))]),
      ]),
      _c(
        "v-card-text",
        { staticClass: "px-0 py-0" },
        [
          _c("p", { staticClass: "px-4" }, [
            _vm._v(
              " Avec une valeur de " +
                _vm._s(
                  _vm.format(
                    _vm.instrument.data[_vm.latestYear].value,
                    _vm.instrument.excel_format
                  )
                ) +
                " sur l'exercice " +
                _vm._s(_vm.latestYear) +
                ", " +
                _vm._s(_vm.company.name) +
                " est en "
            ),
            _c("span", { staticClass: "decision" }, [
              _vm._v(_vm._s(_vm.conformity ? "conformité" : "non-conformité")),
            ]),
            _vm._v(
              " avec les exigences du Standard MSI 20000® au niveau de son " +
                _vm._s(_vm.translateName(_vm.instrument)) +
                ". "
            ),
          ]),
          _c("InstrumentChart", {
            attrs: {
              instrument: _vm.instrument,
              "nb-years": _vm.nbYears,
              "xml-chart": _vm.xmlChart,
            },
          }),
          _c("div", { staticClass: "v-data-table v-data-table--dense" }, [
            _c("div", { staticClass: "v-data-table__wrapper" }, [
              _c("table", [
                _c("tbody", [
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.instrumentData, function (d, year) {
                        return _c("th", { key: year }, [
                          _vm._v(" " + _vm._s(year) + " "),
                        ])
                      }),
                      _c("th", [
                        _vm._v("Moyenne de"),
                        _c("br"),
                        _vm._v(" la période"),
                      ]),
                      _c("th", [_vm._v("Référent MSI")]),
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _vm._l(_vm.instrumentData, function (data) {
                        return _c("td", { key: data.id, staticClass: "data" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  data.value,
                                  _vm.instrument.excel_format
                                )
                              ) +
                              " "
                          ),
                        ])
                      }),
                      _c("td", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(
                            _vm.format(
                              _vm.average(_vm.instrumentData),
                              _vm.instrument.excel_format
                            )
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "data" }, [
                        _vm._v(
                          _vm._s(_vm.instrument.ref_comp === ">" ? "≥" : "≤") +
                            " " +
                            _vm._s(
                              _vm.format(
                                _vm.instrument.ref_val,
                                _vm.instrument.excel_format
                              )
                            )
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }