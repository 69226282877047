import { render, staticRenderFns } from "./StockExchangeDetails.vue?vue&type=template&id=77e78732&"
import script from "./StockExchangeDetails.vue?vue&type=script&lang=ts&"
export * from "./StockExchangeDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VCol,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77e78732')) {
      api.createRecord('77e78732', component.options)
    } else {
      api.reload('77e78732', component.options)
    }
    module.hot.accept("./StockExchangeDetails.vue?vue&type=template&id=77e78732&", function () {
      api.rerender('77e78732', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/stock/StockExchangeDetails.vue"
export default component.exports