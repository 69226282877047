var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("picture", [
    _c("source", { attrs: { srcset: _vm.webpSrc, type: "image/webp" } }),
    _c(
      "img",
      _vm._b(
        { class: _vm.classObject, attrs: { src: _vm.src } },
        "img",
        _vm.$attrs,
        false
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }