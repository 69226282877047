






























import { Component, Prop, Vue } from "vue-property-decorator";
import StockSamples from "@/components/stock/StockSamples.vue";
import Flashable from "@/components/stock/Flashable.vue";
import formatter from "@/mixins/formatter";
import { Portfolio } from "@/smartmsi";

@Component({components: {StockSamples, Flashable}, mixins: [formatter]})
export default class StockExchangeDetails extends Vue {
  @Prop({required: true}) Portfolio !: Portfolio;
  @Prop({required: true}) last !: number;
  @Prop({required: true}) samples !: Record<string, number>;
}
