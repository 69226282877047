


























import { Prop, Vue } from "vue-property-decorator";
import { MsiIndex } from "@/smartmsi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import Component from "vue-class-component";

@Component
export default class IndexListItem extends Vue {
  @Prop({required: true}) index!: MsiIndex;

  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }

  viewIndex(index) {
    this.$router.push(`/stock/${index.id}`);
  }
  viewSetting(index,stock,name) {
    this.$router.push(`/stock/${index.id}/setting/${stock}/${name}`);
  }
}
