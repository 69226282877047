import { render, staticRenderFns } from "./UploadFinancialStatements.vue?vue&type=template&id=65b9b67a&"
import script from "./UploadFinancialStatements.vue?vue&type=script&lang=ts&"
export * from "./UploadFinancialStatements.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCheckbox,VFileInput,VIcon,VProgressCircular})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65b9b67a')) {
      api.createRecord('65b9b67a', component.options)
    } else {
      api.reload('65b9b67a', component.options)
    }
    module.hot.accept("./UploadFinancialStatements.vue?vue&type=template&id=65b9b67a&", function () {
      api.rerender('65b9b67a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/company/UploadFinancialStatements.vue"
export default component.exports