var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "404" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "" } },
            [
              _c("div", { staticClass: "text-md-center" }, [
                _c("h1", [_vm._v("404")]),
                _c("h2", { staticClass: "my-3 headline" }, [
                  _vm._v("Page non trouvée"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary" },
                        on: { click: _vm.goHome },
                      },
                      [_vm._v("Retour au tableau de bord ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }