var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "pb-0" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("companies.downloads.financialStatements.title")) +
            " "
        ),
      ]),
      _c(
        "v-card-text",
        [
          _vm.company.availableYears.length
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Start",
                              type: "number",
                              min: _vm.minYear,
                              max: _vm.maxYear,
                            },
                            model: {
                              value: _vm.startYear,
                              callback: function ($$v) {
                                _vm.startYear = $$v
                              },
                              expression: "startYear",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "End",
                              type: "number",
                              min: _vm.minYear,
                              max: _vm.maxYear,
                            },
                            model: {
                              value: _vm.endYear,
                              callback: function ($$v) {
                                _vm.endYear = $$v
                              },
                              expression: "endYear",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "companies.downloads.financialStatements.notAvailable"
                      )
                    ) +
                    " "
                ),
              ]),
          _c(
            "v-btn",
            {
              attrs: { disabled: !_vm.canDownload, color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.download()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("actions.download")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }