// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/notepad.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* our variables */\n.hint {\n  font-style: italic;\n  font-size: 0.8rem;\n  margin-left: 0.5em;\n  color: #555;\n}\n.ql-editor {\n  font-style: italic;\n}\n.ql-editor p {\n  margin-bottom: 0.5em !important;\n}\n.ql-editor h3,\n.ql-editor h4,\n.ql-editor h5 {\n  margin-bottom: 0.5em !important;\n  font-weight: bold;\n}\n.v-textarea textarea {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat;\n  color: #333;\n  line-height: 1.21rem !important;\n  padding-top: 0.42em !important;\n}", ""]);
// Exports
module.exports = exports;
