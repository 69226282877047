var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-icon", { attrs: { "x-small": "" } }, [
    _vm._v("fa-chevron-" + _vm._s(_vm.row._expand ? "down" : "right")),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }