var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        { attrs: { "two-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h5" }, [
                _vm._v(" " + _vm._s(_vm.Portfolio.name) + " "),
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(_vm.Portfolio.devise)),
              ]),
            ],
            1
          ),
          _c("v-list-item-icon", [
            _c(
              "svg",
              {
                staticClass: "svg-inline--fa fa-analytics fa-w-18 fa-9x",
                staticStyle: { color: "#00FF00" },
                attrs: {
                  "aria-hidden": "true",
                  focusable: "false",
                  "data-prefix": "fas",
                  "data-icon": "analytics",
                  role: "img",
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 576 512",
                },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M510.62 92.63C516.03 94.74 521.85 96 528 96c26.51 0 48-21.49 48-48S554.51 0 528 0s-48 21.49-48 48c0 2.43.37 4.76.71 7.09l-95.34 76.27c-5.4-2.11-11.23-3.37-17.38-3.37s-11.97 1.26-17.38 3.37L255.29 55.1c.35-2.33.71-4.67.71-7.1 0-26.51-21.49-48-48-48s-48 21.49-48 48c0 4.27.74 8.34 1.78 12.28l-101.5 101.5C56.34 160.74 52.27 160 48 160c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-4.27-.74-8.34-1.78-12.28l101.5-101.5C199.66 95.26 203.73 96 208 96c6.15 0 11.97-1.26 17.38-3.37l95.34 76.27c-.35 2.33-.71 4.67-.71 7.1 0 26.51 21.49 48 48 48s48-21.49 48-48c0-2.43-.37-4.76-.71-7.09l95.32-76.28zM400 320h-64c-8.84 0-16 7.16-16 16v160c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V336c0-8.84-7.16-16-16-16zm160-128h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-320 0h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zM80 352H16c-8.84 0-16 7.16-16 16v128c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V368c0-8.84-7.16-16-16-16z",
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "text-h2 text-center" },
                [
                  _c("flashable", {
                    attrs: { value: _vm.last, format: "decimal", decimals: 3 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("stock-samples", { attrs: { samples: _vm.samples, last: _vm.last } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }