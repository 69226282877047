var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shown
    ? _c(
        "v-card",
        [
          _c(
            "v-app-bar",
            { attrs: { flat: "", dense: "" } },
            [
              _c("v-icon", { attrs: { dense: "", small: "" } }, [
                _vm._v(_vm._s(_vm.icon)),
              ]),
              _c("v-card-title", { staticClass: "text-subtitle-1 mb-0" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { staticClass: "handle", attrs: { small: "", icon: "" } },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("fa-arrows-alt"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.shown = false
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("fa-times"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.collapsed = !_vm.collapsed
                    },
                  },
                },
                [
                  _vm.collapsed
                    ? _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("fa-chevron-down"),
                      ])
                    : _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("fa-chevron-up"),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.collapsed
            ? _c(
                "v-card-text",
                { class: _vm.cardTextClass },
                [_vm._t("default")],
                2
              )
            : _vm._e(),
          _vm.hasFooterSlot && !_vm.collapsed
            ? _c("v-card-actions", [_vm._t("footer")], 2)
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }