var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-wrapper" },
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  label: "Overlays",
                  items: _vm.overlays,
                  "item-text": "name",
                  "item-value": "code",
                },
                model: {
                  value: _vm.overlay,
                  callback: function ($$v) {
                    _vm.overlay = $$v
                  },
                  expression: "overlay",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  label: "Oscillators",
                  items: _vm.oscillators,
                  "item-text": "name",
                  "item-value": "code",
                },
                model: {
                  value: _vm.oscillator,
                  callback: function ($$v) {
                    _vm.oscillator = $$v
                  },
                  expression: "oscillator",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.loading
        ? _c("Chart", {
            ref: "chart",
            attrs: {
              "constructor-type": "stockChart",
              options: _vm.chartOptions,
            },
          })
        : _c(
            "v-flex",
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "purple",
                          indeterminate: "",
                        },
                      }),
                      _c("div", { staticClass: "text--purple mt-2" }, [
                        _vm._v("Loading .."),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }