var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "widget-base",
    {
      staticClass: "mb-4",
      attrs: {
        title: "Memo",
        icon: "fa-clipboard",
        "card-text-class": "ma-0 pa-0",
      },
    },
    [
      _c("v-textarea", {
        attrs: {
          dense: "",
          name: "Vous pouvez saisir un mémo içi",
          "auto-grow": "",
          loading: !_vm.loaded || _vm.saving,
        },
        model: {
          value: _vm.memo,
          callback: function ($$v) {
            _vm.memo = $$v
          },
          expression: "memo",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }