var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md8: "" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Edit Profile")]),
                  _c("v-card-subtitle", [_vm._v("Complete your profile")]),
                  _c(
                    "v-card-text",
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.user.name))]),
                      _c("i", [_vm._v(_vm._s(_vm.user.email))]),
                      _c("br"),
                      _vm.impersonator
                        ? [
                            _c("hr"),
                            _vm._v(
                              " Impersonated by " +
                                _vm._s(_vm.impersonator.email) +
                                " "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md8: "" } },
            [
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(" Browser "),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(" Device "),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(" IP "),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(" Logged at "),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.sessions, function (session) {
                            return _c("tr", { key: session.id }, [
                              _c(
                                "td",
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.browserIcon(
                                          session.parsed.browser.name
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(session.parsed.browser.name) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.osIcon(session.parsed.os.name))
                                    ),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(session.parsed.os.name) + " "
                                  ),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(session.ip))]),
                              _c("td", [_vm._v(_vm._s(session.created_at))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }