var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.name, rules: _vm.theRules, delay: 1000 },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (validationContext) {
          return [
            _vm.component === "vue-editor"
              ? _c(
                  "vue-editor",
                  _vm._b(
                    {
                      staticClass: "bg-white",
                      attrs: {
                        editorToolbar: _vm.editorToolbar,
                        "error-messages": validationContext.errors[0],
                        state: _vm.getValidationState(validationContext),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("input", _vm.v)
                        },
                      },
                      model: {
                        value: _vm.v,
                        callback: function ($$v) {
                          _vm.v = $$v
                        },
                        expression: "v",
                      },
                    },
                    "vue-editor",
                    _vm.$attrs,
                    false
                  )
                )
              : _vm.component === "ActivitySelector"
              ? _c("ActivitySelector", {
                  attrs: { error: validationContext.errors[0] },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", _vm.v)
                    },
                  },
                  model: {
                    value: _vm.v,
                    callback: function ($$v) {
                      _vm.v = $$v
                    },
                    expression: "v",
                  },
                })
              : _vm.component === "v-radio-group"
              ? _c(
                  "v-radio-group",
                  _vm._b(
                    {
                      attrs: { "error-messages": validationContext.errors[0] },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("input", _vm.v)
                        },
                      },
                      model: {
                        value: _vm.v,
                        callback: function ($$v) {
                          _vm.v = $$v
                        },
                        expression: "v",
                      },
                    },
                    "v-radio-group",
                    _vm.$attrs,
                    false
                  ),
                  [_vm._t("default")],
                  2
                )
              : _c(
                  _vm.component,
                  _vm._g(
                    _vm._b(
                      {
                        ref: "input",
                        tag: "component",
                        attrs: {
                          label: _vm.theLabel,
                          required: _vm.rules && _vm.rules.required,
                          placeholder: _vm.placeholder,
                          "error-messages":
                            _vm.error || validationContext.errors[0],
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$emit("input", _vm.v)
                          },
                          change: function ($event) {
                            return _vm.$emit("input", _vm.v)
                          },
                        },
                        model: {
                          value: _vm.v,
                          callback: function ($$v) {
                            _vm.v = $$v
                          },
                          expression: "v",
                        },
                      },
                      "component",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                ),
            _vm.hint
              ? _c("div", { staticClass: "hint" }, [_vm._v(_vm._s(_vm.hint))])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }