var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "220px", overflow: "hidden" } },
    [
      _c("Chart", { attrs: { options: _vm.gaugeOptions } }),
      _c("debug", [_vm._v(_vm._s(_vm.series))]),
      _c("debug", [_vm._v(_vm._s(_vm.scores))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }